<template>
  <b-card
    no-body
  >
    <b-card-header class="px-1 py-50">
      <h4 class="card-title font-weight-bolder">
        {{ $t('employee.filters') }}
      </h4>
      <div class="d-flex align-items-center justify-content-end">
        <!-- ANCHOR Search -->
        <b-button
          variant="warning"
          class="mr-1"
          :disabled="!canAccess('employee.searchEmployee')"
          @click="$emit('fetch-data')"
        >
          <span class="text-nowrap">
            <feather-icon
              class="cursor-pointer"
              icon="SearchIcon"
              size="16"
            />
            {{ $t('employee.search') }}
          </span>
        </b-button>

        <!-- ANCHOR Clear -->
        <b-button
          variant="danger"
          :disabled="!canAccess('employee.searchEmployee')"
          @click="$emit('reset')"
        >
          <span class="text-nowrap">
            <feather-icon
              class="cursor-pointer"
              icon="XOctagonIcon"
              size="16"
            />
            {{ $t('employee.clear') }}
          </span>
        </b-button>
      </div>
    </b-card-header>

    <b-card-body class="py-0">
      <b-row class="d-flex justify-content-start">
        <!-- ANCHOR Date Type -->
        <b-col
          cols="12"
          sm="6"
          md="3"
          class=""
        >
          <b-form-group
            :label="$t('promotionAg.filters.dateType')"
          >
            <v-select
              class="w-100"
              :options="typeOfDateFilterOptions"
              label="label"
              :clearable="false"
              :value="typeFilter"
              :placeholder="$t('placeholderSelect')"
              :reduce="val => val.value"
              @input="(val) => $emit('update:typeFilter', val)"
            >
              <template #option="data">
                <span>
                  {{ $t(data.label) }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ $t(data.label) }}
                </span>
              </template>

              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- ANCHOR From -->
        <b-col
          cols="12"
          sm="6"
          md="3"
          class=""
        >
          <b-form-group
            :label="$t('promotionAg.filters.from')"
          >
            <b-form-datepicker
              v-model="startDate"
              reset-button
              close-button
              :locale="$i18n.locale"
              :placeholder="$t('placeholderSelect')"
              :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
              @input="(val) => $emit('update:startDate', val)"
            />
          </b-form-group>
        </b-col>
        <!-- ANCHOR To -->
        <b-col
          cols="12"
          sm="6"
          md="3"
          class=""
        >
          <b-form-group
            :label="$t('promotionAg.filters.to')"
          >
            <b-form-datepicker
              v-model="endDate"
              today-button
              reset-button
              close-button
              :locale="$i18n.locale"
              :placeholder="$t('placeholderSelect')"
              :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
              @input="(val) => $emit('update:endDate', val)"
            />
          </b-form-group>
        </b-col>
        <!-- ANCHOR Airline -->
        <b-col
          cols="12"
          sm="6"
          md="3"
          class=""
        >
          <b-form-group
            :label="$t('promotionAg.filters.airline')"
          >
            <v-select
              class="w-100"
              :options="airlineFilterOptions"
              label="label"
              :clearable="false"
              :value="airline"
              :placeholder="$t('placeholderSelect')"
              :reduce="val => val.value"
              @input="(val) => $emit('update:airline', val)"
            >
              <template #option="data">
                <span>
                  {{ $te(data.label) ? $t(data.label) : data.label }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ $te(data.label) ? $t(data.label) : data.label }}
                </span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
  BButton, BFormGroup, BFormDatepicker,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import debounce from 'lodash/debounce'

import {
  resolveEmployeeTypeVariant, resolveEmployeeType, typeOfDateFilterOptions, airlineFilterOptions,
} from '@/constants/selectOptions'
import api from '@/api'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormGroup,
    VSelect,
    BFormDatepicker,
  },
  props: {
    typeFilter: {
      type: String,
      default: null,
    },
    startDate: {
      type: String,
      default: null,
    },
    endDate: {
      type: String,
      default: null,
    },
    airline: {
      type: String,
      default: null,
    },
  },
  setup() {
    const createdByOptions = ref([])
    const isLoading = ref(false)
    const createdByOptionsDefault = ref([])

    const fetchEmployeesByFilterSearch = async (search = '') => {
      createdByOptions.value = []
      isLoading.value = true
      try {
        const data = await api.employee.fetchEmployeesByFilter({ types: ['ADM', 'OPE'], searchText: search })
        if (data && !search) {
          createdByOptionsDefault.value = data.items
        }
        createdByOptions.value = data?.items || []
      } catch (error) {
        console.error(error)
      } finally {
        isLoading.value = false
      }
    }

    function handleOpenCreatedBy() {
      if (createdByOptionsDefault.value.length === 0) {
        fetchEmployeesByFilterSearch()
      } else {
        createdByOptions.value = createdByOptionsDefault.value
      }
    }

    const handleSearchCreatedBy = debounce(search => {
      if (search) fetchEmployeesByFilterSearch(search)
    }, 500)

    return {
      createdByOptions,
      handleSearchCreatedBy,
      fetchEmployeesByFilterSearch,
      isLoading,
      handleOpenCreatedBy,
      typeOfDateFilterOptions,
      airlineFilterOptions,

      resolveEmployeeTypeVariant,
      resolveEmployeeType,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
</style>
